import { consultantsUrl, usersUrl } from '../constants/api';
import http from './httpService';

export async function getConsultants(rowsPerPage, rowToSkip, order, orderBy) {
  const url = `${consultantsUrl}?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInConsultants(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${consultantsUrl}?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function createConsultant(consultant, organisations) {
  const { data: consultantRes } = await http.post(consultantsUrl, consultant);
  await http.put(
    `${consultantsUrl}/${consultantRes.id}/organisations`,
    organisations
  );
  const { data: organisationsRes } = await http.get(
    `${consultantsUrl}/${consultantRes.id}/organisations`
  );
  return { ...consultantRes, companies: organisationsRes.length };
}

export async function createConsultantWithoutOrg(consultant) {
  const { data } = await http.post(consultantsUrl, consultant);
  return data;
}

export async function updateConsultant(id, val, organisations) {
  const { data: consultantRes } = await http.put(
    `${consultantsUrl}/${id}`,
    val
  );
  await http.put(`${consultantsUrl}/${id}/organisations`, organisations);
  const { data: organisationsRes } = await http.get(
    `${consultantsUrl}/${id}/organisations`
  );
  return { ...consultantRes, companies: organisationsRes.length };
}

export async function getConsultantOrganization(id) {
  const { data } = await http.get(`${consultantsUrl}/${id}/organisations`);
  return data;
}

export async function getUser(email) {
  const { data } = await http.get(`${usersUrl}/${email}`);
  return data;
}

export async function updateUser(id, val) {
  const { data } = await http.put(`${usersUrl}/${id}`, val);
  return data;
}

export async function usersChangeActivStatus(ids, bool) {
  const newIds = ids.map((id) => `ids=${id}&`);
  const formatted = newIds.join('');
  const { data } = await http.patch(`${usersUrl}/change-state?${formatted}`, {
    state: bool
  });
  return data;
}

export async function usersDeleteStatus(id) {
  const { data } = await http.delete(`${usersUrl}/${id}`);
  return data;
}

export async function getUserMe() {
  const { data } = await http.get(`${usersUrl}/me`);
  return data;
}

export async function getDpo(rowsPerPage, rowToSkip, order, orderBy) {
  const url = `${usersUrl}/dpo?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getAllDpo() {
  const url = `${usersUrl}/dpo`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInDpo(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/dpo?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getGdpr(rowsPerPage, rowToSkip, order, orderBy) {
  const url = `${usersUrl}/gdpr_trv?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInGdpr(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/gdpr_trv?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getCollaborators(rowsPerPage, rowToSkip, order, orderBy) {
  const url = `${usersUrl}/collaborator?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInCollaborators(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/collaborator?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getApprentices(rowsPerPage, rowToSkip, order, orderBy) {
  const url = `${usersUrl}/apprentices?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInApprentices(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/apprentices?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getCorrespondantRGPD(
  rowsPerPage,
  rowToSkip,
  order,
  orderBy
) {
  const url = `${usersUrl}/correspondantRGPD?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInCorrespondantRGPD(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/correspondantRGPD?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function getOperationalResponsible(
  rowsPerPage,
  rowToSkip,
  order,
  orderBy
) {
  const url = `${usersUrl}/operationalResponsible?take=${rowsPerPage}&skip=${rowToSkip}&orderBy[0][${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function searchInOperationalResponsible(
  rowsPerPage,
  rowToSkip,
  query,
  order,
  orderBy
) {
  const url = `${usersUrl}/operationalResponsible?take=${rowsPerPage}&skip=${rowToSkip}&searchQuery=${query}&orderBy[${orderBy}]=${order}`;
  const { data } = await http.get(url);
  return data;
}

export async function updatePasswordRenewField(id) {
  const { data } = await http.post(`${usersUrl}/updateRenewPassword/${id}`);
  return data;
}

export async function getUserById(id) {
  const { data } = await http.get(`${usersUrl}/id/${id}`);
  return data;
}

export async function searchInUsers(query) {
  const url = `${usersUrl}/all-users?searchQuery=${query}`;
  const { data } = await http.get(url);
  return data;
}
